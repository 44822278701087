@if (currentState && currentState.currentChapter) {
  <div *ngIf="playerState$ | async as state" class="audio-player fixed-bottom d-flex flex-column bg-body-dark text-light p-2">
    
    <!-- Upper Section: Album Cover, Track Info, and Controls -->
    <div class="d-flex align-items-center mb-2">

      <!-- Track Info -->
      <div class="me-3 flex-grow-1">
        <div class="novel-title fw-bold text-truncate">{{ state.currentNovel?.title || 'Novel Title' }}</div>
        <div class="chapter-title">Chapter {{ state.currentChapter?.chapterId || '1' }}</div>
      </div>

      <!-- Play/Pause Button -->
      <button class="btn btn-link text-light me-2" (click)="togglePlayPause()">
        <i [ngClass]="{'bi-play-fill': !state.isPlaying, 'bi-pause-fill': state.isPlaying}"></i>
      </button>

      <!-- Next Chapter Button -->
      <button 
        *ngIf="state.queue.length > 0"
        class="btn btn-link text-light me-2" 
        (click)="playNextChapter()"
      >
        <i class="bi-skip-forward-fill"></i>
      </button>

      <!-- Current Time / Duration -->
      <span class="time me-1">{{ currentTime }}</span>
      <span class="time me-2 d-none d-md-inline">/ {{ duration }}</span>

      <!-- Additional Controls: Like, Share, Volume -->
      <div class="d-flex align-items-center">
        <!-- <button class="btn btn-link text-light me-2">
          <i class="bi-share"></i>
        </button> -->
        <button class="btn btn-link text-light me-2" (click)="toggleMute()">
          <i [ngClass]="{'bi-volume-up': !isMuted, 'bi-volume-mute': isMuted}"></i>
        </button>
        <!-- Download Button -->
        <a [href]="state.currentChapter?.chapterUrl" download class="btn btn-link text-light me-2">
          <i class="bi-download"></i>
        </a>
      </div>
    </div>
    
    <!-- Progress Bar (Now Positioned Below Controls and Info) -->
    <div class="progress mb-2" style="height: 8px; cursor: pointer;" (click)="onProgressClick($event)">
      <div
        class="progress-bar bg-primary"
        role="progressbar"
        [style.width.%]="progress"
        [style.transition]="'none'"
        aria-valuemin="0"
        aria-valuemax="100"
        [attr.aria-valuenow]="progress">
      </div>
    </div>
  </div>
}